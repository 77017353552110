import React from 'react';
import PropTypes from 'prop-types';
import { setContext } from '@apollo/client/link/context';

import { ApolloClient, ApolloProvider, InMemoryCache, split, from } from '@apollo/client';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { useAuth } from './AuthProvider';
import { Auth0User } from '@bloomays-lib/types.shared';
import { Header, LoaderGlobal } from '@bloomays-lib/ui.shared';
import { errorLinkMonitoring } from '@bloomays-lib/common.monitoring/browser';
import { ApolloErrorTypes, getGQLErrorCode, getNetworkErrorCode, isGraphQLError } from '../helpers/error';
import { getErrorStatusCode } from '@bloomays-lib/utils.shared';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const { VITE_BLOOMERS_API, VITE_TALENTS_API } = import.meta.env;

type AuthorizedApolloProviderProps = {
  children: React.ReactNode;
};

const AuthorizedApolloProvider = ({ children }: AuthorizedApolloProviderProps): JSX.Element => {
  const auth = useAuth();
  let isLoading;
  let user: Auth0User | undefined | null;
  let getAccessTokenSilently: (() => void) | undefined;

  if (auth) {
    user = auth.user;
    isLoading = auth.isLoading;
    getAccessTokenSilently = auth.getAccessTokenSilently;
  }

  if (isLoading) {
    return (
      <>
        <Header />
        <LoaderGlobal />
      </>
    );
  }

  const url = new URL(VITE_BLOOMERS_API || 'localhost:4000');
  const secure = url.protocol.includes('https');

  const wsLinkBloomer = new GraphQLWsLink(
    createClient({
      url: `ws${secure ? 's' : ''}://${url.host}/subscriptions`,
    }),
  );

  const httpLinkBloomers = createUploadLink({
    uri: VITE_BLOOMERS_API,
    credentials: 'include',
  });

  const httpLinkTalents = createUploadLink({
    uri: `${VITE_TALENTS_API}graphql`,
    credentials: 'include',
  });

  const authLink = setContext(async (_, { headers }) => {
    let token;
    if (getAccessTokenSilently) {
      token = !user?.token && (await getAccessTokenSilently());
    } else {
      token = user?.token;
    }
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'Apollo-Require-Preflight': 'true',
      },
    };
  });

  const filterCodes = ['TOKEN_EXPIRED', 'UNKNOWN_BLOOMER'];

  const filterExceptions = (error: ApolloErrorTypes) => {
    let code: string | undefined;
    if (isGraphQLError(error)) {
      code = getGQLErrorCode(error);
    } else {
      code = getNetworkErrorCode(error);
    }
    return filterCodes.includes(code || '') || getErrorStatusCode(error) < 500;
  };

  const multiLinkBloomer = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLinkBloomer,
    httpLinkBloomers,
  );

  const monitLink = errorLinkMonitoring({ filterExceptions });

  const apolloClient = new ApolloClient({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link: split(
      (operation) => {
        return operation.getContext().clientName === 'api.talents';
      },
      from([monitLink, authLink, httpLinkTalents]), //if above
      from([monitLink, authLink, multiLinkBloomer]),
    ),
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Bloomer: { keyFields: ['recordId'], merge: true },
        Notification: { keyFields: ['recordId'], merge: true },
        Mission: { keyFields: ['recordId'] },
        Acivity: { keyFields: ['recordId'] },
        Invitation: { keyFields: ['recordId'] },
        Society: { keyFields: ['recordId'] },
        Contact: { keyFields: ['recordId'] },
        Pricing: { keyFields: ['recordId'] },
        Query: {
          fields: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            allMissions: { missions: offsetLimitPagination(['isActive']) },
            me: {
              // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
              merge: true,
            },
          },
        },
      },
    }),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.object,
  location: PropTypes.string,
};
